const errorValues = [
  /Non-Error promise rejection captured with keys: body, code, date, message, requestId/i,
  /Non-Error promise rejection captured with value: undefined/i
]

function checkError(error, event) {
  if (error && error.message) {
    for (let i = 0; i < errorValues.length; i++) {
      if (error.message.match(errorValues[i])) {
        return null
      }
    }
  }
  return event

  /*if (
    error &&
    error.exception &&
    error.exception.values &&
    error.exception.values.length > 0 &&
    error.exception.values[0].value
  ) {
    for (let i = 0; i < errorValues.length; i++) {
      if (error.exception.values[0].value === errorValues[i]) {
        return null
      }
    }
  } else {
    return null
  }
  return error*/
}

export { checkError }
