import React, { Component } from 'react'
import { Button, Dialog, DataTable, Column, FilterMatchMode, InputText } from 'primereact'
import { observer, inject } from 'mobx-react'
import axios from 'axios'
import moment from 'moment'
import checkAccess from '../../util/checkAccess'
import 'primeflex/primeflex.scss'
import { UserViewerModal } from '../../components/UserSelection/UserViewerModal'
import { getProfilePic } from '../../util/profilePictureHelper'
import { Persona, PersonaSize } from '@fluentui/react'

const UpcomingReviewCycleAutomations = inject(
  'userStore',
  'appStore'
)(
  observer(
    class UpcomingReviewCycleAutomations extends Component {
      constructor(props, context) {
        super(props, context)
        this.state = {
          cycles: [],
          reviewCycle: undefined,
          isEditing: false,
          deleteDialogVisible: false,
          isStartReviewCycleDialogVisible: false,
          pageWithParam: false,
          automationName: '',
          globalFilterValue: '',
          filters: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS }
          }
        }
      }

      componentDidMount() {
        this.getCycles()
      }

      getCycles = () => {
        this.setState({ isRequesting: true })
        axios
          .get('/api/admin/getUpcomingAutomationCycles')
          .then(async response => {
            const groupMap = {
              1: 'Everyone in the company',
              2: 'Every Teamflect user in the company',
              3: 'Selected users',
              4: 'Filtered users in the company',
              5: 'Upload a csv file'
            }

            const reviewCyclesProcessed = await Promise.all(
              response.data?.map(async rc => {
                return {
                  ...rc,
                  revieweeGroupLabel: !rc.reviewee ? groupMap[rc.revieweeGroup] : null,
                  formattedStartDate: moment(rc.nextCycleDate).format('YYYY-MM-DD'),
                  reviewee: rc.reviewee,
                  profilePictureUrl: rc.reviewee
                    ? await getProfilePic(this.props.userStore.graphAccessToken, rc.reviewee.userPrincipalName)
                    : null
                }
              })
            )
            this.setState({ cycles: reviewCyclesProcessed, isRequesting: false })
          })
          .catch(error => {
            console.log('error ' + error)
            this.setState({ isRequesting: false })
          })
      }

      onGlobalFilterChange = e => {
        const value = e.target.value
        let filters = { ...this.state.filters }
        filters['global'].value = value

        this.setState({ filters, globalFilterValue: value })
      }

      renderMain = () => {
        let header = (
          <div
            className='p-clearfix'
            style={{ width: '100%', justifyContent: 'space-between', alignItems: 'center', display: 'flex' }}
          >
            <span
              style={{
                justifyContent: 'center',
                fontSize: 16,
                fontWeight: 600,
                alignSelf: 'center',
                color: '#505050',
                marginTop: 4
              }}
            >
              Automations starting in next 30 days{' '}
            </span>
            <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
              <div className='p-input-icon-left' style={{ position: 'relative' }}>
                <i className='pi pi-search' />
                <InputText
                  value={this.state.globalFilterValue}
                  onChange={this.onGlobalFilterChange}
                  placeholder='Search'
                />
              </div>
            </div>
          </div>
        )

        return (
          <div className='grid'>
            <div className='col'>
              <DataTable
                paginator
                rows={15}
                loading={this.state.isRequesting}
                value={this.state.cycles}
                header={header}
                showGridlines
                responsiveLayout='scroll'
                filters={this.state.filters}
                globalFilterFields={['automationName']}
                emptyMessage='No cycles found.'
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
              >
                <Column
                  field='automationName'
                  style={{ wordBreak: 'break-word', alignItems: 'center' }}
                  header='Automation Name'
                  sortable
                />
                <Column
                  style={{ wordBreak: 'break-word', alignItems: 'center', cursor: 'pointer' }}
                  header='Reviewee Group'
                  sortable
                  body={(rowData, column) => {
                    return rowData.revieweeGroupLabel ? (
                      <span
                        style={{
                          color: '#444691'
                        }}
                        onClick={() => {
                          this.setState({ openUserViewerModal: true, reviewCycle: rowData })
                        }}
                      >
                        {rowData.revieweeGroupLabel}
                      </span>
                    ) : (
                      <Persona
                        imageUrl={rowData.profilePictureUrl}
                        imageInitials={rowData.profilePictureInitial}
                        text={rowData.reviewee.displayName}
                        size={PersonaSize.size32}
                      />
                    )
                  }}
                />
                <Column
                  field='formattedStartDate'
                  style={{ wordBreak: 'break-word', alignItems: 'center' }}
                  header='Start Date'
                  sortable
                />
              </DataTable>
            </div>

            {this.state.openUserViewerModal && (
              <UserViewerModal
                isOpen={this.state.openUserViewerModal}
                onClose={() => {
                  this.setState({ openUserViewerModal: false })
                }}
                userStore={this.props.userStore}
                whichUserTitle={'Reviewees'}
                handleUpdateProperty={() => {
                  console.log('handleUpdateProperty')
                }}
                reviewCycle={this.state.reviewCycle}
                disableUserSelection={true}
              />
            )}
          </div>
        )
      }

      render() {
        if (checkAccess(this.props.userStore.role)) {
          return this.renderMain()
        } else {
          return <p>You are not authorized to visit this page</p>
        }
      }
    }
  )
)

export default UpcomingReviewCycleAutomations
