import * as React from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useEffect, useState } from 'react'
import { getProfilePic } from '../../util/profilePictureHelper'
import { Persona, PersonaSize } from '@fluentui/react'
import axios from 'axios'

const UserViewerModal = ({
  isOpen,
  onClose,
  userStore,
  whichUserTitle,
  reviewCycle,
  handleUpdateProperty,
  disableUserSelection
}) => {
  const [selectedUsersWithProfilePics, setSelectedUsersWithProfilePics] = useState([])
  const [photosLoading, setPhotosLoading] = useState(true)
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [excludedUsersWithProfilePics, setExcludedUsersWithProfilePics] = useState([])
  const [activeTab, setActiveTab] = useState('selected')
  const [selectedUsers, setSelectedUsers] = useState([])

  const fetchProfilePics = async user => {
    user.profilePicture = await getProfilePic(userStore.graphAccessToken, user.userPrincipalName)
    setSelectedUsersWithProfilePics(prevState => {
      return prevState.map(u => (u.oid === user.oid || u.id === user.id ? user : u))
    })
  }

  const getSelectedUsers = async () => {
    setPhotosLoading(true)

    try {
      const response = await axios.post('/api/admin/getUsersBySelectionOption', {
        selectionOption: reviewCycle.revieweeGroup,
        selectedUsers: reviewCycle.selectedUsers,
        selectedManagerCheck: reviewCycle.selectedManagerCheck,
        selectedRevieweeGroupsForDepartment: reviewCycle.selectedRevieweeGroupsForDepartment,
        selectedRevieweeGroupsForCountry: reviewCycle.selectedRevieweeGroupsForCountry,
        selectedRevieweeGroupsForJobTitle: reviewCycle.selectedRevieweeGroupsForJobTitle,
        selectedRevieweeGroupsForLocation: reviewCycle.selectedRevieweeGroupsForLocation,
        selectedRevieweeGroupsForDomain: reviewCycle.selectedRevieweeGroupsForDomain,
        selectedOptionForAccountCreatedDay: reviewCycle.selectedOptionForAccountCreatedDay,
        howManyDaysForAccountCreated: reviewCycle.howManyDaysForAccountCreated,
        selectedCustomAttributeGroups: reviewCycle.selectedCustomAttributeGroups
      })
      setSelectedUsers(response.data)
      return response.data
    } catch (error) {
      console.log('error ' + error)
      setSelectedUsers([])
      return []
    } finally {
      // setUsersLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setActiveTab('selected')

      const fetchedUsers = await getSelectedUsers()
      if (fetchedUsers.length > 0) {
        if (reviewCycle.exclusionList === undefined) {
          reviewCycle.exclusionList = []
        }

        let selectedUsersList = fetchedUsers.filter(user => {
          return !reviewCycle.exclusionList.find(
            excludedUser => excludedUser.oid === user.oid || excludedUser.oid === user.id
          )
        })
        let notSelectedUsersList = fetchedUsers.filter(user => {
          return reviewCycle.exclusionList.find(
            excludedUser => excludedUser.oid === user.oid || excludedUser.oid === user.id
          )
        })
        setSelectedUsersWithProfilePics(selectedUsersList)
        setExcludedUsersWithProfilePics(notSelectedUsersList)
        setSearchResults(selectedUsersList)
        selectedUsersList.forEach(user => fetchProfilePics(user))
        notSelectedUsersList.forEach(user => fetchProfilePics(user))
      }
      setPhotosLoading(false)
    }

    fetchData()
  }, [])

  const handleSearch = (query, userList) => {
    if (query) {
      const filteredUsers = userList.filter(
        user =>
          user.displayName.toLowerCase().includes(query.toLowerCase()) ||
          user.userPrincipalName.toLowerCase().includes(query.toLowerCase()) ||
          user.mail?.toLowerCase().includes(query.toLowerCase())
      )
      setSearchResults(filteredUsers)
    } else {
      setSearchResults(userList)
    }
  }

  const onSearchChange = e => {
    const query = e.target.value
    setSearchQuery(query)
    if (activeTab === 'selected') {
      handleSearch(query, selectedUsersWithProfilePics)
    } else if (activeTab === 'excluded') {
      handleSearch(query, excludedUsersWithProfilePics)
    }
  }

  return (
    <Dialog
      visible={isOpen}
      closable={true}
      style={{
        width: '40vw',
        height: '520px'
      }}
      modalProps={{ isBlocking: true }}
      header={
        <div style={{ display: 'flex', fontSize: 20, color: '#313131', fontWeight: 600 }}>
          {whichUserTitle + (selectedUsers.length > 0 ? ` (${selectedUsers.length})` : '')}
        </div>
      }
      onHide={() => {
        onClose()
        setActiveTab('selected')
      }}
    >
      {/* Tabs to toggle between selected and excluded users */}
      <div style={{ display: 'flex', marginBottom: '16px' }}>
        <div
          onClick={() => {
            if (reviewCycle.revieweeGroup === 5) {
              return
            }
            setActiveTab('selected')
            handleSearch(searchQuery, selectedUsersWithProfilePics)
          }}
          style={{
            flex: 1,
            height: '52px',
            padding: '16px',
            fontWeight: activeTab === 'selected' ? 'bold' : 'normal',
            borderBottom: activeTab === 'selected' ? '1px solid #444691' : 'none',
            cursor: 'pointer',
            color: activeTab === 'selected' ? '#444691' : '#313131',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          Selected {whichUserTitle.toLowerCase()} ({selectedUsersWithProfilePics.length})
        </div>
        <div
          onClick={() => {
            if (reviewCycle.revieweeGroup === 5) {
              return
            }
            setActiveTab('excluded')
            handleSearch(searchQuery, excludedUsersWithProfilePics)
          }}
          style={{
            flex: 1,
            height: '52px',
            padding: '16px',
            fontWeight: activeTab === 'excluded' ? 'bold' : 'normal',
            borderBottom: activeTab === 'excluded' ? '1px solid #444691' : 'none',
            cursor: 'pointer',
            color: activeTab === 'excluded' ? '#444691' : '#313131',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          Removed from list ({excludedUsersWithProfilePics.length})
        </div>
      </div>

      {/* Search Input */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <div style={{ position: 'relative', width: '100%' }}>
          <i
            className='pi pi-search leftIcon'
            style={{
              position: 'absolute',
              left: '14px',
              top: '56%',
              transform: 'translateY(-50%)',
              width: '20px',
              height: '20px',
              color: '#9A9A9A'
            }}
          />
          <input
            type='text'
            placeholder={`Search in the list`}
            value={searchQuery}
            onChange={onSearchChange}
            style={{
              width: '100%',
              padding: '8px 8px 8px 40px',
              fontSize: '16px',
              border: '1px solid #CECECE',
              borderRadius: '4px'
            }}
          />
        </div>
      </div>

      {/* User List */}
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        {photosLoading && <ProgressSpinner />}
        {photosLoading && (
          <span
            style={{
              fontSize: 18,
              fontWeight: 600,
              color: '#505050',
              textAlign: 'center',
              marginTop: 48
            }}
          >
            Loading users...
          </span>
        )}
        {!photosLoading && activeTab === 'selected' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {searchResults.map(user => (
              <div
                key={user.oid}
                style={{
                  overflow: 'hidden',
                  marginBottom: 8,
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Persona
                  secondaryText={user.jobTitle}
                  text={user.displayName}
                  showSecondaryText={true}
                  size={PersonaSize.size40}
                  imageUrl={user.profilePicture}
                  styles={{
                    secondaryText: { color: '#313131', fontSize: 12, fontWeight: 400 },
                    primaryText: { color: '#313131', fontSize: 16, fontWeight: 700 }
                  }}
                />
              </div>
            ))}
          </div>
        )}
        {!photosLoading && activeTab === 'excluded' && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {searchResults.map(user => (
              <div
                key={user.oid}
                style={{
                  overflow: 'hidden',
                  marginBottom: 8,
                  flexDirection: 'row',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <Persona
                  secondaryText={user.jobTitle}
                  text={user.displayName}
                  showSecondaryText={true}
                  size={PersonaSize.size40}
                  imageUrl={user.profilePicture}
                  styles={{
                    secondaryText: { color: '#313131', fontSize: 12, fontWeight: 400 },
                    primaryText: { color: '#313131', fontSize: 16, fontWeight: 700 }
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Dialog>
  )
}

export { UserViewerModal }
