const USER_ROLE = {
  User: 0,
  ReportReader: 1,
  GlobalAdministrator: 2,
  ConfigurationEditor: 3,
  ScopedAdministrator: 4
}

const EMPLOYMENT_TYPE = {
  FULL_TIME: 0,
  PART_TIME: 1,
  INTERNSHIP: 2,
  CONTRACT: 3,
  FREELANCE: 4,
  VOLUNTEER: 5
}

const COMPETENCY_LEVEL = {
  ENTRY: 0,
  INTERMEDIATE: 1,
  PROFFESSIONAL: 2,
  LEADERSHIP: 3,
  C: 4
}

export { USER_ROLE, EMPLOYMENT_TYPE, COMPETENCY_LEVEL }
