const MODULES = {
  MEETINGS: 'MEETINGS',
  GOALS: 'GOALS',
  REVIEWS: 'REVIEWS',
  SURVEYS: 'SURVEYS',
  RECOGNITIONS: 'RECOGNITIONS',
  FEEDBACK: 'FEEDBACK',
  TASKS: 'TASKS',
  NOTES: 'NOTES',
  GENERAL: 'GENERAL',
  LICENSE: 'LICENSE',
  INDIVIDUAL_DEVELOPMENT_PLAN: 'INDIVIDUAL_DEVELOPMENT_PLAN'
}

export { MODULES }
