import axios from 'axios'
import { PersonaPresence } from 'office-ui-fabric-react'

/*export  function getProfilePic (accessToken,UPN) {
  return 'x'
}*/
const graph = require('@microsoft/microsoft-graph-client')

export async function getProfilePic(accessToken, UPN, dimensions) {
  if (!dimensions) {
    dimensions = '96x96'
  }
  return new Promise(async (resolve, reject) => {
    const client = graph.Client.init({
      authProvider: done => {
        done(null, accessToken)
      }
    })

    try {
      let res = await client
        .api(`users/${UPN}/photos/${dimensions}/$value`)
        //.header('Cache-Control', 'no-cache')
        .responseType('blob')
        .get()
      const blobUrl = window.URL.createObjectURL(res)
      resolve(blobUrl)
    } catch (error) {
      resolve('')
    }
  })
}
