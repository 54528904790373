import * as React from 'react'
import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import { useEffect } from 'react'

const RefreshModal = ({}) => {
  useEffect(() => {
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  }, [])

  return (
    <Dialog
      visible={true}
      closable={false}
      style={{
        width: '500px',
        height: '250px'
      }}
      modalProps={{ isBlocking: true }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}
      >
        <ProgressSpinner />
        <span
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: '#505050',
            textAlign: 'center',
            marginTop: 48
          }}
        >
          Session expired. Page will automatically refresh.
        </span>
      </div>
    </Dialog>
  )
}

export { RefreshModal }
